import { FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_ERROR } from '../actions-type';

const categoriesLoaded = (newCategories) => {
    return{
        type: FETCH_CATEGORIES_SUCCESS,
        payload: newCategories
    }
}

const categoriesRequested = () => {
    return {
        type: FETCH_CATEGORIES_REQUEST
    }
}

const categoriesError = () => {
    return {
        type: FETCH_CATEGORIES_ERROR
    }
}

export {
    categoriesLoaded,
    categoriesRequested,
    categoriesError
}