import React, { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { translationsLoaded } from '../../actions/translationsActions';

//components
import Nav from '../sections/Nav';
import Header from '../sections/Header';
import SectionServices from '../sections/SectionServices';
import SectionFormApply from '../sections/SectionFormApply';
import AboutProject from '../sections/AboutProject';
// import Contacts from './components/sections/Contacts';
import Footer from '../sections/Footer';

//hoc
import { withApiService } from '../hoc';


class App extends Component {

    async componentDidMount(){
        const { translationsLoaded } = this.props;
        const { getTranslations } = this.props.apiService;
        const { data } = await getTranslations();
        translationsLoaded(data);
    }

    render(){
        return (
            <div className="App">
                <Nav />
                <Header />
                <SectionServices />
                <SectionFormApply />
                <AboutProject/>
                {/* <Contacts /> */}
                <Footer />
            </div>
        );
    }
}

const mapDispatchToProps = {
    translationsLoaded
}

export default connect(null, mapDispatchToProps)(withApiService()(App));
