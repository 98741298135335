import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {Helmet} from "react-helmet";

import { connect } from 'react-redux';

//pages
import Main from './components/pages/Main';
import Page404 from './components/pages/Page404';

const App = ({ strings, lang }) => {

    return (
        <>
            <Helmet>
                <title>{strings ? strings['title'][lang] : null}</title>
                <meta name="description" content={strings ? strings['description'][lang] : null} />
            </Helmet>
            <Router>
                <Switch>
                    <Route path="/" exact component={Main} />
                    <Route path="*" component={Page404} />
                </Switch>
            </Router>
        </>
    );
}

const mapStateToProps = ({main: { lang }, translations: { strings }}) => {
    return{
        strings,
        lang
    }
}

export default connect(mapStateToProps)(App);
