import React from 'react';

import { connect } from 'react-redux';

const Header = ({ strings, lang }) => {
    return(
        <section className="bg-home d-flex align-items-center bg-animation-left task-management-home" style={{height: 'auto'}} id="home">
            <div className="container position-relative" style={{zIndex: 1}}>
                <div className="row justify-content-center">
                    <div className="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="title-heading margin-top-100">
                                    <h1 className="text-white title-dark mb-3">{strings ? strings.main_title[lang] : null}</h1>
                                </div>
                            </div>
                        </div>

                        <div className="row pb-5 header-blocks" >
                            <div className="col-lg-6 col-md-6 mt-4 pt-2">
                                <div className="card explore-feature rounded text-center bg-white shadow">
                                    <div className="card-body">
                                        <div className="content mt-3">
                                            <h5 ><i>“{strings ? strings['quote-1-text'][lang] : null}”</i></h5>
                                            <h6 className="mb-0 text-right">{strings ? strings['quote-1-author'][lang] : null}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 mt-4 pt-2">
                                <div className="card explore-feature rounded text-center bg-white shadow">
                                    <div className="card-body">
                                        <div className="content mt-3">
                                            <h5 ><i>“{strings ? strings['quote-2-text'][lang] : null}”</i><br/><br/></h5>
                                            <h6 className="mb-0 text-right">{strings ? strings['quote-2-author'][lang] : null}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ); 
}


const mapStateToProps = ({main: { lang }, translations: { strings }}) => {
    return{
        strings,
        lang
    }
}

export default connect(mapStateToProps)(Header);