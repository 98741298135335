import React, { Component } from 'react';

import { connect } from 'react-redux';
import { changeLang } from '../../actions/mainActions';


class Nav extends Component{

    handlerChangeLang = (lang) => {
        const { changeLang } = this.props;
        changeLang(lang);
    }

    render(){

        const langs = [
            {
                name: 'УКР',
                slug: 'ua'
            },
            {
                name: 'ENG',
                slug: 'en'
            },
            {
                name: 'РУС',
                slug: 'ru'
            },
        ];

        document.addEventListener('scroll', () => {
            const scroll = window.scrollY;
            const element = document.getElementById('topnav');
    
            if (scroll >= 50) {
                element.classList.add('nav-sticky');
            } else {
                element.classList.remove('nav-sticky');
            }
        });

        const { lang, strings } = this.props;

        const langsView = langs.map(({name, slug}) => (<span key={slug} className={`${lang === slug ? 'active-lang' : ''}`} onClick={() => this.handlerChangeLang(slug)}>{name}</span>));

        return(
            <header id="topnav" className="defaultscroll sticky">
                <div className="container">
                    <div>
                        <a className="logo" href="/">
                            <img src="/images/logo-mincult.png" className="l-dark" height="80" alt="" />
                            <img src="/images/logo-mincult-light.png" className="l-light" height="80" alt=""/>
                        </a>
                    </div>
                    <div className="buy-button">
                        <div className="login-btn-primary lang-list">
                            {langsView}
                        </div>
                        <div className="text-white login-btn-light lang-list">
                            {langsView}
                        </div>
                    </div>
                    <div id="navigation">
                        <ul className="navigation-menu nav-light">
                            <li><a href="#project">{strings ? strings.aboutproject[lang] : null}</a></li>
                            <li><a href="#contacts">{strings ? strings.contacts[lang] : null}</a></li>
                        </ul>
                    </div>
                </div>
            </header>
        )
    }

}

const mapDispatchToProps = {
    changeLang
}

const mapStateToProps = ({ main: { lang }, translations: { strings } }) => {
    return{
        lang,
        strings
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);