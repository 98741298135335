import React from 'react';
import Parser from 'html-react-parser';

import { connect } from 'react-redux';

const AboutProject = ({ strings, lang }) => {
    return(
        <>
            <div id="project"></div>
            <section className="section bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="section-title mb-4 pb-2">
                                <h4 className="title mb-4 text-center">{ strings ? strings.abouttitle[lang] : null }</h4>
                                { strings ? Parser(strings.abouttext[lang]) : null }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = ({main: { lang }, translations: { strings }}) => {
    return{
        strings,
        lang
    }
}

export default connect(mapStateToProps)(AboutProject);