import React from "react";
import Spinner from './inc/spinner';
import ResourceItem from './ResourceItem';

//redux
import { connect } from 'react-redux';

const ServicesList = ({ services: { items, load, error }, activeCategory, lang, categories, userVotes, handlerVote, strings}) => {

    if(load){
        return (
            <Spinner />
        )
    }

    return (
        <div className="row projects-wrapper">
            {
                items.map((item) => {
                    return(
                        activeCategory === 0 || activeCategory === +item.category_id 
                        ? <ResourceItem strings={strings} lang={lang} key={item.id} item={item} categories={categories} userVotes={userVotes} handlerVote={handlerVote} /> 
                        : null
                    );
                })
            }
        </div>
    )
}

const mapStateToProps = ({main: { lang }}) => {
    return{
        lang
    }
}

export default connect(mapStateToProps)(ServicesList);