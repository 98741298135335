import React from 'react';
import Spinner from '../elements/inc/spinner';

import CategoryItem from './CategoryItem';

//redux
import { connect } from 'react-redux';

const CategoriesList = ({ categories: { items, error, load }, activeCategory, handleChangeCategory, lang, strings }) => {

    if(load){
        return (
            <Spinner />
        )
    }

    return(
        <div className="row">
            <ul className="col container-filter list-unstyled categories-filter text-center mb-0" id="filter">
                <li className="list-inline-item">
                    <button type="button" onClick={() => handleChangeCategory(0)} className={`categories-name border d-block text-dark rounded ${activeCategory === 0 ? 'active' : ''}`}>
                        { strings ? strings.all[lang] : null }
                    </button>
                </li>
                {
                    items.map(el => (<CategoryItem key={el.id} handleChangeCategory={handleChangeCategory} activeCategory={activeCategory} lang={lang} item={el} />))
                }
            </ul>
        </div>
    );
}

const mapStateToProps = ({ main: { lang }, translations: { strings } }) => {
    return{
        lang,
        strings
    }
}

export default connect(mapStateToProps)(CategoriesList);