import React, { Component } from 'react';


class ResourceItem extends Component{

    state = {
        votes: 0
    }

    componentDidMount(){
        const { item: { votes } } = this.props;
        this.setState({
            votes: votes || 0
        })
    }

    handlerUserVote = () => {
        const { item: { id }, handlerVote, userVotes } = this.props;
        const { votes } = this.state;

        if(!userVotes.includes(id)){
            handlerVote(id);

            this.setState({
                votes: +votes + 1
            })
        }
    }


    render(){

        const { item: { name, description, url, image_url, category_id, id, translation }, 
            lang, categories, userVotes, strings } = this.props;
        const { votes } = this.state;

        let nameShow, descriptionShow, category, category_name = null;

        category = categories.filter(el => el.id === +category_id);
        if(category){
            if(lang === 'ua'){
                category_name = category.length === 1 ? category[0].name : '';
            }else if(lang === 'ru'){
                category_name = category.length === 1 ? category[0].translation.name_ru : '';
            }else if(lang === 'en'){
                category_name = category.length === 1 ? category[0].translation.name_en : '';
            }
        }

        if(lang === 'ua'){
            nameShow = name;
            descriptionShow = description;
        }else if(lang === 'ru'){
            nameShow = translation.name_ru;
            descriptionShow = translation.description_ru;
        }else if(lang === 'en'){
            nameShow = translation.name_en;
            descriptionShow = translation.description_en;
        }

        return(
            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2" key={id}>
                <div className="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
                    <img src={image_url} className="img-fluid work-image" alt="" />
                    <div className="card-body">
                        <div className="content">
                            <span className="badge badge-primary">{category_name}</span>
                            <h5 className="mt-3"><a href={url} rel="noreferrer" target="_blank" className="text-dark title">{nameShow}</a></h5>
                            <p className="text-muted">{descriptionShow}</p>
                            <div className="post-meta d-flex justify-content-between mt-3">
                                <ul className="list-unstyled mb-0">
                                    <li className="list-inline-item mr-2 mb-0">
                                        <button type="button" className="text-primary like" onClick={this.handlerUserVote}>
                                            <i className={`mdi ${userVotes.includes(id) ? 'mdi-heart' : 'mdi-heart-outline'} mr-1`}></i>
                                            {votes || 0}
                                        </button>
                                    </li>
                                </ul>
                                <a href={url} rel="noreferrer" target="_blank" className="text-primary h6">
                                    { strings ? strings.gotothesite[lang] : null } 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
                                        stroke="currentColor" 
                                        strokeWidth="2" 
                                        strokeLinecap="round" strokeLinejoin="round" 
                                        className="feather feather-arrow-right fea icon-sm">
                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                            <polyline points="12 5 19 12 12 19"></polyline>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResourceItem;