import { FETCH_CATEGORIES_ERROR, FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS } from '../actions-type';

const initialState = {
    load: true,
    error: false,
    items: []
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case FETCH_CATEGORIES_REQUEST:
            return{
                load: true,
                error: false,
                items: []
            }
        case FETCH_CATEGORIES_SUCCESS:
            return{
                ...state,
                load: false,
                error: false,
                items: action.payload
            }
        case FETCH_CATEGORIES_ERROR:
            return{
                ...state,
                load: false,
                error: true,
                items: []
            }
        default:
            return state;
    }

}

export default reducer;