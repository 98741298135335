import React, { Component } from 'react';
import Spinner from '../inc/spinner';

import { connect } from 'react-redux';

//hoc
import { withApiService } from '../../hoc';

class FormApplyService extends Component{

    state = {
        sended: false,
        sending: false,
        sendData: {
            name: '',
            description: '',
            url: ''
        }
    }

    handleInputChange = (event) => {
        const { value, name } = event.target;
        const { sendData } = this.state;

        let cloneSendData = { ...sendData };
        cloneSendData[name] = value;
        this.setState({
            sendData: cloneSendData
        });
    }

    handleSendForm = async (event) => {
        event.preventDefault();

        const { applyNewService } = this.props.apiService;
        this.setState({
            sending: true
        });
        await applyNewService(this.state.sendData);
        this.setState({
            sending: false,
            sended: true
        })
    }

    render(){
        const { sending, sended } = this.state;
        const { name, description, url } = this.state.sendData;
        const { strings, lang } = this.props;

        if(sending){
            <Spinner />
        }

        const form = (<form method="post" action="#" name="contact-form" onSubmit={this.handleSendForm}>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label>{ strings ? strings.applyformnamefield[lang] : null } <span className="text-danger">*</span></label>
                        <div className="position-relative">
                            <input required name="name" onChange={this.handleInputChange} type="text" value={name} className="form-control" placeholder={strings ? strings.applyformnamefield[lang] : ''} />
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>{ strings ? strings.applyformdescfield[lang] : null } </label>
                        <div className="position-relative">
                            <textarea name="description" value={description} onChange={this.handleInputChange} rows="4" className="form-control" placeholder={ strings ? strings.applyformdescfield[lang] : '' }></textarea>
                        </div>
                    </div> 
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>{ strings ? strings.applyformurlfield[lang] : null } <span className="text-danger">*</span></label>
                        <div className="position-relative">
                            <input required name="url" type="text" onChange={this.handleInputChange} value={url} className="form-control" placeholder={ strings ? strings.applyformurlfield[lang] : '' } />
                        </div>
                    </div>                                                                               
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 text-center">
                    <input type="submit" name="send" className="submitBnt btn btn-primary btn-block" value={ strings ? strings.applyformbtnsendfield[lang] : '' } />
                    <div id="simple-msg"></div>
                </div>
            </div>
        </form>);

        return (
            <div className="custom-form mt-4">
                
                { !sended ? form : (<div id="message">{ strings ? strings.applyformsendermess[lang] : null }</div>) }
            </div>
        )
    }
}

const mapStateToProps = ({main: { lang }, translations: { strings }}) => {
    return{
        strings,
        lang
    }
}

export default connect(mapStateToProps)(withApiService()(FormApplyService));