import axios from 'axios';

export default class ApiService{
    
    request = async ({url, method, params, error = 'Something went wrong'}) => {
        try{
            const response = await axios({
                method, 
                url,
                data: {
                    ...params
                }
            });
            return response;
        }catch(err){
            throw new Error(error);
        }
    };

    // getTranslations = async(data) => {
    //     return this.request({ url: '/translation.json', method: "GET" });
    // }
    getTranslations = async() => {
        return this.request({ url: '/api/translations/all', method: "GET" });
    }

    applyNewService = async(data) => {
        return this.request({ url: '/api/resources', method: 'POST', params: { ...data } })
    }

    getAllResources = async() => {
        return this.request({ url: '/api/resources/all', method: "GET" })
    }

    getAllCategories = async() => {
        return this.request({ url: '/api/categories/all', method: "GET" });
    }

    userVote = async(id) => {
        return this.request({ url: `/api/resources/${id}`, method: "PATCH" });
    }
}