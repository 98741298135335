import React from 'react';

const Page404 = () => {

    return(
        <section className="bg-home d-flex align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <div className="text-uppercase mt-4 display-3">404 помилка</div>
                        <div className="text-capitalize text-dark mb-4 error-page"></div>
                        <p className="text-muted para-desc mx-auto">Неправильно набрана адреса або такої сторінки більше не існує</p>

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">  
                        <a href="/" className="btn btn-primary mt-4 ml-2">на головну</a>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Page404;