//MAIN
const CHANGE_LANG = 'CHANGE_LANG';

//services
const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
const FETCH_SERVICES_ERROR = 'FETCH_SERVICES_ERROR';

//categories
const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';

// translations
const FETCH_TRANSLATIONS = 'FETCH_TRANSLATIONS';

export {
    CHANGE_LANG,
    //
    FETCH_SERVICES_SUCCESS,
    FETCH_SERVICES_REQUEST,
    FETCH_SERVICES_ERROR,
    //
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_ERROR,
    FETCH_CATEGORIES_REQUEST,
    //
    FETCH_TRANSLATIONS
}