import React from 'react';
import Parser from 'html-react-parser';

import { connect } from 'react-redux';

import FormApplyService from '../elements/form-apply-service';

const ResourseFormApply = ({ strings, lang }) => {
    return (
        <>
            <div id="contacts"></div>
            <div className="container mt-100 mt-60 pb-5">
                <div className="row justify-content-center">
                    <div className="col-md-6 order-1">
                        <div className="section-title">
                            <h4 className="title mb-4">{ strings ? strings.contacttitle[lang] : null }</h4>
                            { strings ? Parser(strings.contacttext[lang]) : null }
                        </div>
                        <div className="contact-detail mt-5"><div className="content mt-3 overflow-hidden d-block"></div></div>
                    </div>
                    <div className="col-md-6 pt-2 pt-sm-0 order-2">
                        <div className="card shadow rounded border-0">
                            <div className="card-body py-5">
                                <h4 className="card-title">{ strings ? strings.applyformtitle[lang] : null }</h4>
                                <FormApplyService />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({main: { lang }, translations: { strings }}) => {
    return{
        strings,
        lang
    }
}

export default connect(mapStateToProps)(ResourseFormApply);