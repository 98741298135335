import React from 'react';
import './spinner.css';

const Spinner = () => {
    return(
        <div className="center text-center">
            <div className="lds-dual-ring"></div>
        </div>
    )
}

export default Spinner; 