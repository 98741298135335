import { FETCH_SERVICES_SUCCESS, FETCH_SERVICES_REQUEST, FETCH_SERVICES_ERROR } from '../actions-type';


const servicesLoaded = (newServices) => {
    return {
        type: FETCH_SERVICES_SUCCESS,
        payload: newServices
    }
}

const servicesRequested = () => {
    return {
        type: FETCH_SERVICES_REQUEST
    }
}

const servicesError = () => {
    return {
        type: FETCH_SERVICES_ERROR
    }
}

export {
    servicesLoaded,
    servicesRequested,
    servicesError
}