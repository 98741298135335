import React, { Component } from "react";
import Cookie from 'js-cookie';

import { connect } from 'react-redux';
import { compose } from '../../utils';

//actions
import { servicesLoaded, servicesError, servicesRequested } from '../../actions/servicesActions';
import { categoriesError, categoriesLoaded, categoriesRequested } from '../../actions/categoriesActions';

//hoc
import { withApiService } from '../hoc'; 

//components
import CategoriesList from '../elements/CategoriesList';
import ServicesList from '../elements/ServicesList';


class SectionServices extends Component {

    state = {
        activeCategory: 0,
        cookie: []
    }

    handleChangeCategory = (id) => {
        this.setState(
            { activeCategory: id }
        )
    }

    handlerVote = async (resource_id) => {
        const { userVote } = this.props.apiService;
        const { cookie } = this.state;

        let cookieProvide = [...cookie];
        cookieProvide.push(resource_id);
        this.setState({
            cookie: cookieProvide
        });
        Cookie.set('votes', JSON.stringify(cookieProvide), { expires: 365, path: '/' });
        await userVote(resource_id);
    }

    async componentDidMount(){
        const cookie = Cookie.get('votes');
        if(cookie){
            this.setState({
                cookie: JSON.parse(cookie)
            })
        }
        const { 
            apiService, 
            servicesLoaded, servicesRequested, servicesError, 
            categoriesError, categoriesLoaded, categoriesRequested } = this.props;

        //categories
        categoriesRequested();
        try{
            const { data } = await apiService.getAllCategories();
            categoriesLoaded(data);
        }catch(error){
            categoriesError();
            console.error(error);
        }

        //services
        servicesRequested();
        try{
            const { data } = await apiService.getAllResources();
            servicesLoaded(data);
        }catch(error){
            servicesError();
            console.error(error);
        }
    }

    render(){
        const { services, categories, strings } = this.props;
        const { activeCategory, cookie } = this.state;
        return(
            <section className="section">
                <div className="container">
                    <CategoriesList 
                        categories={categories} 
                        activeCategory={activeCategory} 
                        handleChangeCategory={this.handleChangeCategory} />
                    <ServicesList 
                        services={services} 
                        activeCategory={activeCategory} 
                        categories={categories.items} 
                        userVotes={cookie}
                        handlerVote={this.handlerVote}
                        strings={strings}
                        />
                </div>
            </section>
        )
    }
}

//work with redux
const mapStateToProps = ({ services, categories, translations: { strings } }) => {
    return{
        services,
        categories,
        strings
    }
}

const mapDispatchToProps = {
    servicesLoaded,
    servicesError,
    servicesRequested,
    categoriesError,
    categoriesLoaded,
    categoriesRequested
}

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(SectionServices); 