import { CHANGE_LANG } from '../actions-type';

const initialState = {
    lang: 'ua'
}

const reducer = (state = initialState, action) => {

    const { type, payload } = action;
    switch(type){
        case CHANGE_LANG:
            return{
                ...state,
                lang: payload
            }
        default:
            return state;
    }

}

export default reducer;