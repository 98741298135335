import { FETCH_TRANSLATIONS } from '../actions-type';

const reducer = (state = { strings: null }, action) => {

    switch(action.type){
        case FETCH_TRANSLATIONS:
            return {
                ...state,
                strings: action.payload
            };

        default:
            return state;
    }
}

export default reducer;