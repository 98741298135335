import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
//import thunk from 'redux-thunk';

import { serviceReducers, categoriesReducers, mainReducers, translationsReducer } from './reducers';

const reducer = combineReducers({
    services: serviceReducers,
    categories: categoriesReducers,
    main: mainReducers,
    translations: translationsReducer
});

//const middleware = [thunk];

const store = createStore(reducer, {}, composeWithDevTools(applyMiddleware()));

export default store;