import { CHANGE_LANG } from '../actions-type';

const changeLang = (newLang) => {
    return{
        type: CHANGE_LANG,
        payload: newLang
    }
}

export{
    changeLang
}