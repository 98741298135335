import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApiServiceProvider } from './context';
import { BrowserRouter as Router } from 'react-router-dom';

//store
import store from './store';

//component
import App from './App';

import { ApiService } from './services';

const apiService = new ApiService();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ApiServiceProvider value={apiService}>
                <Router>
                    <App />
                </Router>
            </ApiServiceProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
