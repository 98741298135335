import { FETCH_TRANSLATIONS } from '../actions-type';

const translationsLoaded = (translations) => {
    return{
        type: FETCH_TRANSLATIONS,
        payload: translations
    }
}

export {
    translationsLoaded
}