import React, { Fragment } from 'react';

import { connect } from 'react-redux';

const Footer = ({ strings, lang }) => {

    document.addEventListener('scroll', () => {
        const scroll = window.scrollY;
        const element = document.getElementById('back-to-top');

        if (scroll >= window.innerHeight + 100) {
            element.classList.add('show');
        } else {
            element.classList.remove('show');
        }
    });

    return(
        <Fragment>
            <footer className="footer footer-bar">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-sm-8">
                            <div className="text-sm-left"> 
                                <p className="mb-0">{ strings ? strings.copyright[lang] : null }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a href="#" className="btn btn-icon btn-primary back-to-top" id="back-to-top">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-up icons">
                    <line x1="12" y1="19" x2="12" y2="5"></line>
                    <polyline points="5 12 12 5 19 12"></polyline>
                </svg>
            </a>
        </Fragment>
    );
}

const mapStateToProps = ({main: { lang }, translations: { strings }}) => {
    return{
        strings,
        lang
    }
}

export default connect(mapStateToProps)(Footer);