import { FETCH_SERVICES_SUCCESS, FETCH_SERVICES_REQUEST, FETCH_SERVICES_ERROR } from '../actions-type';


const initialState = {
    load: true,
    error: false,
    items: []
};

const reducer = (state = initialState, action) => {

    switch(action.type){
        case FETCH_SERVICES_REQUEST:
            return {
                ...state,
                load: true,
                error: false,
                items: []
            }

        case FETCH_SERVICES_SUCCESS:
            return {
                ...state,
                load: false,
                error: false,
                items: action.payload
            };
        
        case FETCH_SERVICES_ERROR:
            return{
                ...state,
                load: true,
                error: true,
                items: []
                
            }

        default:
            return state;
    }
}

export default reducer;