import React from 'react';

const CategoryItem = ({ handleChangeCategory, activeCategory, item, lang }) => {

    let name;
    if(lang === 'ua'){
        name = item.name
    }else if(lang === 'ru'){
        name = item.translation.name_ru;
    }else if(lang === 'en'){
        name = item.translation.name_en;
    }

    return(
        <li className="list-inline-item">
            <button type="button" 
                onClick={() => handleChangeCategory(item.id)} 
                className={`categories-name border d-block text-dark rounded ${activeCategory === item.id ? 'active' : ''}`}>
                { name }
            </button>
        </li>
    );
}

export default CategoryItem;